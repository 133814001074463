import React from 'react';
import Clock from 'react-live-clock';
import {actionCreators as routerActions} from '../store/RouterActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import mapImage from '../images/map.png';
import resourceImage from '../images/udstyr.png';
import logoImage from '../images/customer_logo.png';
import eventsImage from '../images/events.png';

import './Menu.css';

var printId = "";

const Menu = (props) => {

    const showOrgID = ()=> {
        printId = "orgid:" + localStorage.getItem('orgId');
    }
    const authActions = props.authActions
    return(
        <div className="menu">
            <div  onClick={()=>showOrgID()}>
                <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Copenhagen'} interval={30000}/>
                <div>
                    {printId}
                </div>
            </div>
            <div className="menu-point-container">
                <div className="menu-point"><div onClick={() => props.routerActions.navigate('/')}><img src={require('../images/home.png')} alt='Hjem menu'/></div></div>
                <div className="menu-point"><div onClick={() => props.routerActions.navigate('/plan')}><img src={require('../images/plan.png')} alt='Plan menu'/></div></div>
                <div className="menu-point"><div onClick={() => props.routerActions.navigate('/documents')}><img src={require('../images/documents.png')} alt='Arkiv menu'/></div></div>
                <div className="menu-point" onClick={() => props.routerActions.navigate('/map')}><img src={mapImage} alt='Map menu' /></div>
                <div className="menu-point" onClick={() => props.routerActions.navigate('/resource')}><img src={resourceImage} alt='Resource menu' /></div>
                <div className="menu-point" onClick={() => props.routerActions.navigate('/events')}><img src={eventsImage} alt='events menu' /></div>
                <img style={{position: 'absolute', bottom: 0, left: 0, opacity: 0.4 }} src={logoImage} alt='' />
            </div>

        </div>
    );
};

export default connect(
    state => state,
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(Menu);